import React, { useEffect } from 'react';
import Loading from 'components/Loading';

import { useGlobalContext } from 'contexts';

export default function SilentCallback(): JSX.Element {
  const [, request] = useGlobalContext();
  const { signinSilentCallback } = request;

  useEffect(() => {
    signinSilentCallback()
      .catch((err: any) => console.error('Error in silent callback route:', err));
  }, [signinSilentCallback]);

  return <Loading />;
}
